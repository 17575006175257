<template>
  <div class="Error404">
    {{ $t('layout.NotFound') }}
  </div>
</template>

<script>
export default {
  name: 'Error404',

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('layout.NotFound404')),
    }
  },
}
</script>
